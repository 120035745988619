<template>
  <div class="star clearfixed">
    <div class="nav">
      <router-link class="navItem" to="/dragon_star/about/index"><img src="/img/2021/navIcon1.png" class="navIcon">大赛介绍</router-link>
      <router-link class="navItem" to="/dragon_star/about/organization"><img src="/img/2021/navIcon2.png" class="navIcon">组织机构</router-link>
      <router-link class="navItem" to="/dragon_star/about/leader"><img src="/img/2021/navIcon3.png" class="navIcon">组织领导</router-link>
      
      <router-link class="navItem" to="/dragon_star/about/award_type"><img src="/img/2021/navIcon4.png" class="navIcon">参赛类别</router-link>
      <template v-if="getShow">
        <span class="navItem subNav" :class="{subNavActive: getPathId=='a'}" @click="gotoAwardType('a')">主题赛A:绿色人居类</span>
        <span class="navItem subNav" :class="{subNavActive: getPathId=='b'}" @click="gotoAwardType('b')">主题赛B:绿色产品类</span>
        <span class="navItem subNav" :class="{subNavActive: getPathId=='c'}" @click="gotoAwardType('c')">主题赛C:绿色视觉传达类</span>
        <span class="navItem subNav" :class="{subNavActive: getPathId=='d'}" @click="gotoAwardType('d')">命题赛D:校园绿色空间设计</span>
        <span class="navItem subNav" :class="{subNavActive: getPathId=='e'}" @click="gotoAwardType('e')">命题赛E:邢台平乡童车、儿童电动玩具绿色创新设计</span>
        <span class="navItem subNav" :class="{subNavActive: getPathId=='f'}" @click="gotoAwardType('f')">命题赛F:儿童友好型绿色设计产品</span>
      </template>
     
      <router-link class="navItem" to="/dragon_star/about/guide"><img src="/img/2021/navIcon5.png" class="navIcon">参赛指南</router-link>
      <router-link class="navItem" to="/dragon_star/about/review_process"><img src="/img/2021/navIcon6.png" class="navIcon">评审流程</router-link>
      <router-link class="navItem" to="/dragon_star/about/award_setting"><img src="/img/2021/navIcon6.png" class="navIcon">奖项设置</router-link>
      <router-link class="navItem" to="/dragon_star/about/review_organization"><img src="/img/2021/navIcon8.png" class="navIcon">评审组织</router-link>
      <router-link class="navItem" to="/dragon_star/about/review_criteria"><img src="/img/2021/navIcon9.png" class="navIcon">评审标准</router-link>
      <router-link class="navItem" to="/dragon_star/about/download"><img src="/img/2021/navIcon13.png" class="navIcon">资料下载</router-link>
      <router-link class="navItem" to="/dragon_star/about/feedback"><img src="/img/2021/navIcon10.png" class="navIcon">监督反馈</router-link>
      <router-link class="navItem" to="/dragon_star/about/exemption"><img src="/img/2021/navIcon11.png" class="navIcon">免责须知</router-link>
      <router-link class="navItem" to="/dragon_star/about/contact"><img src="/img/2021/navIcon12.png" class="navIcon">联系我们</router-link>
    </div>
    <div class="pageContent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    getShow(){
      let pathName = this.$route.name
      return pathName=='AboutAwardType'
    },
    getPathId(){
      if(this.getShow){
        let id = this.$route.query.id
        if(id){
          return id
        }
        return 'a'
      }
      return null
    }
  },
  methods:{
    gotoAwardType(id){
      this.$router.push(`/dragon_star/about/award_type?id=${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.star{
  padding: 30px 50px;
  background: #EEEEEF;
  color: #3E3A39;
  .nav{
    float: left;
    width: 200px;
    .navItem{
      display: inline-block;
      padding: 10px 0;
      background: #fff;
      margin-bottom: 15px;
      color: #3E3A39;
      width: 100%;
      text-align: center;
      cursor: pointer;
      .navIcon{
        display: none;
        height: 18px;
        vertical-align: middle;
        padding-right: 5px;
      }
    }
    .router-link-active, .navItem:hover {
      background: rgba($color: #77C250, $alpha: 0.4);
      .navIcon{
        display: inline-block;
      }
    }
    .subNav{
      background: #FDFCFD;
      width: 90%;
      margin-left: 10%;
      text-align: left;
      padding: 5px 15px;
      font-size: 12px;
    }
    .subNavActive, .subNav:hover{
      background: #D0E2F6;
    }
  }
  .pageContent{
    margin-left: 220px;
    background: #fff;
    padding: 50px 15%;
    line-height: 1.8em;
    min-height: 701px;
  }
}
</style>